import React from 'react';

const Header = () => {
  return (
    <div className='header'>
      <h3>
        Calculate your stonks!
      </h3>
    </div>
  );
}

export default Header;