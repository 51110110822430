import React from 'react';
import '../styles/index';

class StockCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.props.title,
      price: this.props.price,
      amount: this.props.amount,
      startSum: this.props.price * this.props.amount,
      change: this.props.change,
      finalSum: this.props.price,
      stonks: 0,
      showComponent: true,
    }
    this.uuid = this.props.uuid
  }
  
  componentDidMount() {
    this.setState({
      finalSum: Math.round(this.state.startSum * 100 * (1 + this.props.change / 100)) / 100
    }, () => {
      this.setState({
        stonks: this.state.finalSum - this.state.startSum
      })
    })
  }
  
  handleInputChange = ({target: {name, value}}) => {
    if (isNaN(value) && name === 'change') {
      if (value === ',') {
        this.setState({
          change: '.'
        })
        return;
      }
      this.setState({
        change: value
      })
      return;
    }
    this.setState({
          [name]: value,
        },
      () => {
        this.setState({
          startSum: this.state.price * this.state.amount
        }, () => this.setState({
          finalSum: Math.round(this.state.startSum * 100 * (1 + this.state.change / 100)) / 100
        }, () => {
          this.setState({
            stonks: this.state.finalSum - this.state.startSum
          })
          window.localStorage.setItem(
            this.uuid,
            JSON.stringify({
              title: this.state.title,
              price: this.state.price,
              amount: this.state.amount,
              change: this.state.change
            })
            )
        }))
      }
    );
  }
  
  handleDestroyCard = () => {
    window.localStorage.removeItem(this.uuid);
    this.setState({showComponent: false});
  }

  render() {
    const stonksColor = this.state.stonks >= 0 ? 'green' : 'red'
    return this.state.showComponent && (
      <div className='stock-card'>
        <div className='title-bar'>
          <span className='close-button' onClick={this.handleDestroyCard}>&#x2715;</span>
        </div>
        <div className='stock-card-body'>
          <div className='stock-card-input-headers'>
            <form>
              <div className='stock-card-input-line title'>
                <h3>Название: </h3>
                <input
                    name='title'
                    type='text'
                    value={this.state.title || ''}
                    onChange={this.handleInputChange}
                />
              </div>
        
              <div className='stock-card-input-line price'>
                <p>Стоимость за 1 шт. </p>
                <input
                    name='price'
                    type='text'
                    value={this.state.price || ''}
                    onChange={this.handleInputChange}
                />
              </div>
        
              <div className='stock-card-input-line amount'>
                <p>Количество: </p>
                <input
                    name='amount'
                    type='number'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    value={this.state.amount || ''}
                    onChange={this.handleInputChange}
                />
              </div>
        
              <div className='stock-card-input-line change'>
                <p>Изменение (в %): </p>
                <input
                    name='change'
                    type='number'
                    pattern='[0-9,\.]*'
                    value={this.state.change || ''}
                    className='stock-change-input'
                    onChange={this.handleInputChange}
                />
              </div>
            </form>
          </div>
    
          <div className='stock-card-calculated'>
            <h3>Расчёт: </h3>
      
            <div className='stock-card-input-line initial-sum-price'>
              <p>Начальная суммарная цена:</p>
              <div style={{marginLeft: 5}}>{this.state.startSum}</div>
            </div>
      
            <div className='stock-card-input-line corrected-sum-price'>
              <p>Суммарная цена с учётом курса:</p>
              <div style={{marginLeft: 5}}>{this.state.finalSum}</div>
            </div>
            <div className='stock-card-input-line income'>
              <p>Прибыль:</p>
              <div style={Object.assign({}, {marginLeft: '5px'}, {color: stonksColor})}>
                {this.state.stonks > 0 && '+'} {(this.state.stonks).toFixed(3)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default StockCard;