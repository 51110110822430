import React from "react";
import './App.css';
import StockCard from "./components/StockCard";
import Header from "./components/Header";

class App extends React.Component {
  state = {
    stockCards: [],
    cardsCount: 0,
  }
  
  initStonks = () => {
    let cards = []
    if (window.localStorage.length < 1) {
      return [];
    }
    for (let uuid in window.localStorage) {
      let item = window.localStorage.getItem(uuid);
      if (!item) {
        continue
      }
      let { title, price, amount, change, key } = JSON.parse(item);
      cards.push(
        <StockCard
          title={title}
          price={price}
          amount={amount}
          change={change}
          key={key}
          uuid={uuid}
        />
      )
    }
    return cards;
  }
  
  uuid = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r :(r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
  }
  
  handleAddStockCard = () => {
    const uuid = this.uuid()
    this.setState({
      cardsCount: this.state.cardsCount + 1
    });
    this.setState({
      stockCards: [
        ...this.state.stockCards,
        {
          title: null,
          price: null,
          amount: null,
          key:this.state.cardsCount,
          uuid: uuid
        }],
    });
    window.localStorage.setItem(uuid, JSON.stringify({title:'',price:0,amount:0,change:0,key:this.state.cardsCount}))
  }

  render() {
    const initialStonks = this.initStonks();
    const cardsContainer = initialStonks.length
        ? initialStonks
        : this.state.stockCards.map(card =>
          <StockCard
            title={card.title}
            price={card.price}
            amount={card.amount}
            change={card.change}
            key={card.key}
            uuid={card.uuid}
          />
        )
    return (
      <div className="App">
        <Header/>
        <header className="App-header">
          {
            cardsContainer.length
            ? cardsContainer
            : (
              <div>
                <div>Нет акций для расчёта</div>
              </div>
              )
          }
          <div onClick={this.handleAddStockCard} className='add-stock-card-button'>+ Добавить</div>
        </header>
      </div>
    );
  }
}

export default App;
